.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 3em;
  padding-top: 2em;
}

.logo {
  height: 3em;
  width: 1.5em;
  background-color: #525252;
}

.title-split {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.city-banner {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 4em 0;
  place-items: center;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* optional, to darken background */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 50%; /* Adjust the width based on the size you want */
  max-width: 600px;
}

/* .city-banner h1 { */
/*   grid-column-start: 2; */
/* } */
/**/
/* .city-banner select { */
/*   height: 2em; */
/*   width: 10em; */
/*   justify-self: end; */
/* } */

.middle-content {
  display: grid;
  justify-self: flex-start;
  padding: 0 4em 0;
  grid-template-columns: 3fr 2fr;
  gap: 2em;
}

.middle-child {
  place-self: center stretch;
}

.bar-graph-container {
  width: 100%;
  height: 400px;
  margin: 1em 0 0;
}

.other-metrics {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1em;
}
