.table-page {
  margin: 4em 0;
  display: grid;
  place-items: center;
}
.filtered-table {
  font-family: Arial, sans-serif;
  max-width: 1000px;
  margin: 0 auto;
}

.filters {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.filter-select {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.data-table {
  width: 100%;
  border-collapse: collapse;
}

.data-table th,
.data-table td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}

.data-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.data-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.data-table tr:hover {
  background-color: #f5f5f5;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px; /* Space between table and pagination */
}

.pagination button {
  padding: 10px 20px; /* Adjust the size of the button */
  margin: 0 10px; /* Space between buttons */
  background-color: #007bff; /* Button color */
  color: white; /* Text color */
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.pagination button:disabled {
  background-color: #cccccc; /* Disabled button color */
  cursor: not-allowed;
}

.pagination span {
  margin: 0 10px; /* Space between text and buttons */
  font-weight: bold; /* Style the text */
}
