.form-page{
    display: grid;
    place-items: center;
    height: 100vh;
}

.form-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  h1 {
    text-align: center;
    color: #2c3e50;
    margin-bottom: 10px;
  }
  
  p {
    text-align: center;
    color: #7f8c8d;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
  }
  
  .grid-item {
    display: flex;
    flex-direction: column;
  }

  label {
    margin-bottom: 5px;
    font-weight: bold;
    color: #2c3e50;
  }

  input[type="text"],
  input[type="tel"],
  input[type="date"],
  select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    box-sizing: border-box;
  }

  input[type="file"] {
    width: 100%;
    padding: 8px 0;
  }
  
  button {
    width: 100%;
    padding: 10px;
    background-color: #27ae60;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
  }
  
  button:hover {
    background-color: #219653;
  }
  .file-input {
    display: none;
  }
  .custom-file-upload {
    display: inline-block;
    padding: 10px 20px;
    background-color: #4CAF50; /* Green background */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  .custom-file-upload:hover {
    background-color: #45a049; /* Darker green on hover */
  }
  @media (max-width: 600px) {
    .form-container {
      padding: 15px;
    }
  
    .grid {
      grid-template-columns: 1fr;
      gap: 10px;
    }
  
    button {
      font-size: 14px;
    }
  }